<template>
  <validation-observer ref="simpleRules">
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <item-form />
        <list-items-form />
        <b-button
          variant="primary"
          @click="submitForm"
        >
          Kaydet
        </b-button>
      </b-tab>
      <b-tab
        :disabled="!dataItem.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="dataItem.id"
          id-components="36"
        />
      </b-tab>
    </b-tabs>
  </validation-observer>
</template>

<script>
import {
  BButton, BTab, BTabs,
} from 'bootstrap-vue'

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemForm from '@/views/Admin/Report_content/ItemForm.vue'
import ListItemsForm from '@/views/Admin/Report_content/ListItemsForm.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'Index',
  components: {
    SeoForm,
    BTab,
    BTabs,
    ValidationObserver,
    BButton,
    ItemForm,
    ListItemsForm,
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      itemsForm: false,
      selectItems: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['reportContent/dataItem']
    },
    saveData() {
      return this.$store.getters['reportContent/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.getData()
      }
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('reportContent/getDataItem')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('reportContent/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
