<template>
  <b-card>
    <title-text />
    <sub-title-text />
    <content-text />
    <bottom-content-text />
  </b-card>
</template>
<script>
import TitleText from '@/views/Admin/Report_content/elements/TitleText.vue'
import SubTitleText from '@/views/Admin/Report_content/elements/SubTitleText.vue'
import ContentText from '@/views/Admin/Report_content/elements/ContentText.vue'
import BottomContentText from '@/views/Admin/Report_content/elements/BottomContentText.vue'
import { BCard } from 'bootstrap-vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    ContentText,
    BottomContentText,
    TitleText,
    SubTitleText,
  },
  computed: {
    dataItem() {
      return this.$store.getters['reportContent/dataItem']
    },
  },
}
</script>
