<template>
  <b-card no-body>
    <b-card-body>
      <b-form-group
        v-for="(item,key) in dataList"
        :key="item.id"
      >
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="success"
              @click="saveItem(key)"
            >
              <FeatherIcon icon="SaveIcon" />
              Kaydet
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="dataList[key].title"
            placeholder="İçerik"
          />
          <b-input-group-append>
            <b-button
              variant="danger"
              @click="removeItem(key)"
            >
              <FeatherIcon icon="TrashIcon" />
              Sil
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-card-body>
    <b-card-footer>
      <b-button
        variant="info"
        size="sm"
        @click="addRow"
      >
        <FeatherIcon icon="PlusCircleIcon" />
        İçerik Ekle
      </b-button>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardFooter,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ListItemsForm',
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
  },
  computed: {
    dataList() {
      return this.$store.getters['reportContentItems/dataList']
    },
    saveData() {
      return this.$store.getters['reportContentItems/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getDataList()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.getDataList()
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('reportContentItems/getDataList')
    },
    addRow() {
      this.dataList.push({
        id: null,
        title: null,
      })
    },
    saveItem(key) {
      this.$store.dispatch('reportContentItems/saveData', this.dataList[key])
    },
    removeItem(key) {
      if (this.dataList[key].id) {
        this.$swal({
          title: this.$store.state.app.removeTitle,
          text: this.$store.state.app.removeMessage,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$store.state.app.removeConfirm,
          cancelButtonText: this.$store.state.app.removeCancel,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('reportContentItems/removeData', this.dataList[key].id)
            this.$swal({
              icon: 'success',
              title: this.$store.state.app.removeResultTitle,
              text: this.$store.state.app.removeResultMessage,
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      } else {
        this.dataList.splice(key, 1)
      }
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
